import React, { useState, useRef } from 'react';
import TextCheckbox from './text-checkbox';
import options from '../config.json';
import useKeyboardShortcuts from '../useKeyboardShortcuts';
import ShortcutIcon from '../ShortcutIcon';
import { maskVertices, restoreColorByMasks } from '../Marker';
import styles from './styles.module.css';
import { AnnotationData, Attribute, Label, ReactSetter } from '../types';
import * as THREE from 'three';
import { SingleAttributeSelect, MultiAttributeSelect } from './AttributeSelect';

// key codes corresponding to shortcut ids (0 to 15)
const keyCodes = [
    'Digit2',
    'Digit3',
    'Digit4',
    'Digit5',
    'Digit6',
    'Digit7',
    'Digit8',
    'Digit9',
    'KeyI',
    'KeyU',
    'KeyY',
    'KeyT',
    'KeyR',
    'KeyE',
    'KeyW',
    'KeyQ',
];

const attributesById: Record<string, Attribute> = {};
options.attributes.forEach((attribute: Attribute) => {
    attributesById[attribute.id] = attribute;
});

interface LabelSelectProps {
    label: Label | null;
    onChange: (newLabel: Label | null) => void;
    visibleLabels: Label[];
    setVisibleLabels: ReactSetter<Label[]>;
    isFilterFocused: boolean;
    setIsFilterFocused: ReactSetter<boolean>;
    annotationData: AnnotationData | undefined;
    setAnnotationData: ReactSetter<AnnotationData | undefined>;
    geometry: THREE.BufferGeometry | null;
    saveSnapshot: () => void;
    baseColorAttr: React.MutableRefObject<THREE.BufferAttribute | undefined>;
    filterInputRef: React.MutableRefObject<HTMLInputElement | null>;
}

function LabelSelect({
    label,
    onChange,
    visibleLabels,
    setVisibleLabels,
    isFilterFocused,
    setIsFilterFocused,
    annotationData,
    setAnnotationData,
    geometry,
    saveSnapshot,
    baseColorAttr,
    filterInputRef,
}: LabelSelectProps) {
    // filter labels to those in the specific jaw
    const jaw = annotationData?.jaw;
    const keyShortcutToLabel: { [shortcutId: number]: Label } = {}; // map from shortcut id (0 to 15) to the label
    const keyLabelToShortcut: { [labelId: number]: number } = {}; // map from label id to shortcut id (0 to 15)
    const labels = options.labels.filter(l => {
        if (l.group === 'Tooth') {
            if ((jaw === 'upper' && l.id > 16) || (jaw === 'lower' && l.id <= 16)) {
                return false;
            }
            if (jaw === 'upper') {
                const shortcutId = l.id - 1;
                keyShortcutToLabel[shortcutId] = l;
                keyLabelToShortcut[l.id] = shortcutId;
            } else if (jaw === 'lower') {
                const shortcutId = l.id - 17;
                keyShortcutToLabel[shortcutId] = l;
                keyLabelToShortcut[l.id] = shortcutId;
            }
        }
        return true;
    });

    // For dealing with filtering
    const [filterText, setFilterText] = useState('');
    const [expandedAttribute, setExpandedAttribute] = useState('');

    const onFilterTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newInput = e.currentTarget.value;
        setFilterText(newInput);
    };

    const filterTextPredicate = (value: { label: string }) => {
        if (!filterText) {
            return true;
        }
        return value.label.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
    };

    const shortcutPrefix = isFilterFocused ? '^' : '';

    function handleSelect(label: Label | null) {
        if (label && !visibleLabels.includes(label)) {
            const newVal = visibleLabels.slice();
            newVal.push(label);
            setVisibleLabels(newVal);
        }
        onChange && onChange(label);
    }

    function handleAttributeChange(
        groupId: string,
        labelId: number,
        attributeId: string,
        attributeValue: string | string[]
    ) {
        console.log('handleAttributeChange', groupId, labelId, attributeId, attributeValue);
        const attributes = annotationData?.attributes || {};
        saveSnapshot();
        setAnnotationData({
            ...annotationData,
            attributes: {
                ...attributes,
                [`${groupId}-${labelId}`]: {
                    ...attributes?.[`${groupId}-${labelId}`],
                    [attributeId]: attributeValue,
                },
            },
        });
    }

    function handleLabelMove(
        groupId: string,
        sourceLabelId: number,
        targetLabelId: '*Prep Copy*' | '*Trash*' | number
    ) {
        if (!geometry) {
            return;
        }
        console.log('handleLabelMove', groupId, sourceLabelId, targetLabelId);
        const attrName = `${groupId}_mask`;
        const maskAttr = geometry.getAttribute(attrName);
        if (!maskAttr) {
            return;
        }
        saveSnapshot();
        const verticesToUpdate: number[] = [];
        if (targetLabelId === '*Prep Copy*') {
            const prepMaskAttr = geometry.getAttribute('PrepTooth_mask');
            if (!prepMaskAttr) {
                return;
            }
            for (let vertexId = 0; vertexId < prepMaskAttr.count; vertexId++) {
                if (maskAttr.getX(vertexId) === sourceLabelId) {
                    prepMaskAttr.setX(vertexId, 1);
                    verticesToUpdate.push(vertexId);
                }
            }
        } else {
            const setLabelId = targetLabelId === '*Trash*' ? 0 : targetLabelId;
            for (let vertexId = 0; vertexId < maskAttr.count; vertexId++) {
                if (maskAttr.getX(vertexId) === sourceLabelId) {
                    maskAttr.setX(vertexId, setLabelId);
                    verticesToUpdate.push(vertexId);
                }
            }
        }
        maskVertices.reset(geometry);
        restoreColorByMasks(verticesToUpdate, geometry, visibleLabels, baseColorAttr.current);
    }

    useKeyboardShortcuts(['Digit1', ...keyCodes], (code: string) => {
        if (code === 'Digit1') {
            handleSelect(null);
        } else {
            const pos = keyCodes.indexOf(code);
            const l = keyShortcutToLabel[pos];
            handleSelect(l ?? null);
        }
    });

    function toggleArrayElement(l: Label, array: Label[], setFunction: ReactSetter<Label[]>) {
        const _array = array.slice();
        const i = _array.indexOf(l);
        if (i >= 0) {
            _array.splice(i, 1);
        } else {
            _array.push(l);
        }
        setFunction(_array);
    }

    // Focus filter input box when F is pressed
    useKeyboardShortcuts(['KeyF'], (code: string) => {
        if (filterInputRef.current) {
            filterInputRef.current.focus();
        }
    });
    // De-select the filter input box when escape is pressed
    useKeyboardShortcuts(['Escape'], (code: string) => {
        if (filterInputRef.current) {
            filterInputRef.current.blur();
        }
    });

    return (
        <div className={styles.dropdownList}>
            <div
                className={`option ${label ? '' : styles.selected}`}
                onClick={() => {
                    handleSelect(null);
                }}
            >
                <ShortcutIcon character={`${shortcutPrefix}1`} />
                <span className={styles.label}>Move tool</span>
            </div>

            <div className={styles.labelsHeader}>
                <span>
                    <ShortcutIcon
                       character={`${shortcutPrefix}F`}
                    />
                    <input
                        ref={filterInputRef}
                        placeholder="Filter label classes"
                        value={filterText}
                        onChange={onFilterTextChange}
                        onFocus={() => setIsFilterFocused(true)}
                        onBlur={() => setIsFilterFocused(false)}
                    />
                </span>
                <span className={styles.spacer}></span>
                <span>
                    <TextCheckbox
                        text={visibleLabels.length > 0 ? 'Hide all' : 'Show all'}
                        checked={true}
                        onChange={() => {
                            setVisibleLabels(visibleLabels.length > 0 ? [] : options.labels);
                        }}
                    />
                </span>
            </div>

            {labels.filter(filterTextPredicate).map((l, index) => (
                <div key={l.label} className={`option ${l === label ? styles.selected : ''}`}>
                    <span
                        key="selector"
                        className={styles.selector}
                        onClick={() => {
                            handleSelect(l);
                        }}
                    >
                        {l.group === 'Tooth' && keyLabelToShortcut[l.id] !== undefined && (
                            <ShortcutIcon
                                character={`${shortcutPrefix}${keyCodes[keyLabelToShortcut[l.id]!]
                                    ?.replace('Digit', '')
                                    .replace('Key', '')}`}
                            />
                        )}
                        <span key="color" className={styles.colorSquare} style={{ backgroundColor: l.color }} />
                        <span key="spacer" className={styles.spacer}></span>
                        <span key="label" className={styles.label}>
                            {l.label}
                        </span>
                    </span>
                    <span key="spacer" className={styles.spacer}></span>
                    <span key="attributes" className={styles.attributes}>
                        {l.attributes?.map((attributeId, attributeIdx) => {
                            const attribute = attributesById[attributeId];
                            if (!attribute) {
                                return null;
                            }
                            if (attribute.type === 'checkbox') {
                                // retrieve previously selected values
                                let selectedValues: string | string[] =
                                    (annotationData?.attributes?.[`${l.group}-${l.id}`] ?? {})[attributeId] ?? [];
                                // convert from individual string to list of strings
                                if (typeof selectedValues === 'string') {
                                    selectedValues = [selectedValues];
                                }
                                // special case to handle legacy None
                                if (selectedValues && selectedValues.length === 0 && selectedValues[0] === 'None') {
                                    selectedValues = [];
                                }
                                return (
                                    <span key={attributeIdx} className={styles.attributeSelector}>
                                        {attribute.label}:
                                        <MultiAttributeSelect
                                            validValues={attribute.values}
                                            selectedValues={selectedValues}
                                            isExpanded={expandedAttribute === `${l.label}-${attributeId}`}
                                            onExpand={() => setExpandedAttribute(`${l.label}-${attributeId}`)}
                                            onCollapse={() => setExpandedAttribute('')}
                                            onChange={selectedValues => {
                                                handleAttributeChange(l.group, l.id, attributeId, selectedValues);
                                            }}
                                        />
                                    </span>
                                );
                            } else if (attribute.type === 'radio') {
                                // retrieve previously selected value
                                let selectedValue: string | string[] =
                                    (annotationData?.attributes?.[`${l.group}-${l.id}`] ?? {})[attributeId] ??
                                    attribute.values[0] ??
                                    'None';
                                // convert from list of strings to individual string
                                if (typeof selectedValue !== 'string') {
                                    selectedValue = selectedValue[0] ?? attribute.values[0] ?? 'None';
                                }
                                return (
                                    <span key={attributeIdx} className={styles.attributeSelector}>
                                        {attribute.label}:
                                        <SingleAttributeSelect
                                            validValues={attribute.values}
                                            selectedValue={selectedValue}
                                            isExpanded={expandedAttribute === `${l.label}-${attributeId}`}
                                            onExpand={() => setExpandedAttribute(`${l.label}-${attributeId}`)}
                                            onCollapse={() => setExpandedAttribute('')}
                                            onChange={selectedValue => {
                                                handleAttributeChange(l.group, l.id, attributeId, selectedValue);
                                            }}
                                        />
                                    </span>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </span>
                    <span key="move" className={styles.moves}>
                        Move to:
                        <select
                            name={`${l.group}-${l.id}-move`}
                            onChange={e => {
                                const intValue = parseInt(e.currentTarget.value);
                                const labelValue = isNaN(intValue)
                                    ? (e.currentTarget.value as '*Trash*' | '*Prep Copy*')
                                    : intValue;
                                handleLabelMove(l.group, l.id, labelValue);
                            }}
                            value={l.id}
                        >
                            {labels
                                .filter(moveToLabel => l.group === moveToLabel.group)
                                .map((moveToLabel, moveToIndex) => {
                                    return (
                                        <option key={moveToIndex} value={moveToLabel.id}>
                                            {l.id === moveToLabel.id ? '-----' : moveToLabel.label}
                                        </option>
                                    );
                                })}
                            <option key={-1} value={'*Trash*'}>
                                *Trash*
                            </option>
                            {l.group === 'Tooth' && (
                                <option key={-2} value={'*Prep Copy*'}>
                                    *Prep Copy*
                                </option>
                            )}
                        </select>
                    </span>
                    <span>
                        <TextCheckbox
                            text={'Show'}
                            checked={visibleLabels.includes(l)}
                            onChange={() => toggleArrayElement(l, visibleLabels, setVisibleLabels)}
                        ></TextCheckbox>
                    </span>
                </div>
            ))}
        </div>
    );
}

export default LabelSelect;
