import './attribute-select.css';

interface MultiAttributeSelectProps {
    validValues: string[];
    selectedValues: string[];
    isExpanded: boolean;
    onExpand: () => void;
    onCollapse: () => void;
    onChange: (selectedValues: string[]) => void;
}

export function MultiAttributeSelect({
    validValues,
    selectedValues,
    isExpanded,
    onExpand,
    onCollapse,
    onChange,
}: MultiAttributeSelectProps) {
    const handleButtonClick = () => {
        if (isExpanded) {
            onCollapse();
        } else {
            onExpand();
        }
    };

    const handleCheckbox = (value: string, checked: boolean) => {
        if (!checked) {
            onChange([...selectedValues, value]);
        } else {
            onChange(selectedValues.filter(v => v !== value));
        }
    };

    return (
        <span className={`attribute-select`}>
            <button onClick={handleButtonClick} className={`attribute-select-button ${isExpanded ? 'active' : ''}`}>
                {selectedValues.length > 1
                    ? `${selectedValues.length} selected`
                    : selectedValues.length === 0
                    ? `None`
                    : selectedValues[0]}
            </button>
            <span className={`attribute-select-dropdown ${isExpanded ? '' : 'hidden'}`}>
                {validValues.map((value, idx) => {
                    const checked = selectedValues.includes(value);
                    return (
                        <span key={idx} className={`attribute-select-dropdown-item`}>
                            <input type="checkbox" checked={checked} onChange={() => handleCheckbox(value, checked)} />
                            <span onClick={() => handleCheckbox(value, checked)}>{value}</span>
                        </span>
                    );
                })}
            </span>
        </span>
    );
}

interface SingleAttributeSelectProps {
    validValues: string[];
    selectedValue: string;
    isExpanded: boolean;
    onExpand: () => void;
    onCollapse: () => void;
    onChange: (selectedValue: string) => void;
}

export function SingleAttributeSelect({
    validValues,
    selectedValue,
    isExpanded,
    onExpand,
    onCollapse,
    onChange,
}: SingleAttributeSelectProps) {
    const handleButtonClick = () => {
        if (isExpanded) {
            onCollapse();
        } else {
            onExpand();
        }
    };

    return (
        <span className={`attribute-select`}>
            <button onClick={handleButtonClick} className={`attribute-select-button ${isExpanded ? 'active' : ''}`}>
                {selectedValue}
            </button>
            <span className={`attribute-select-dropdown ${isExpanded ? '' : 'hidden'}`}>
                {validValues.map((value, idx) => {
                    const checked = selectedValue === value;
                    return (
                        <span key={idx} className={`attribute-select-dropdown-item`}>
                            <input type="radio" checked={checked} onChange={() => onChange(value)} />
                            <span onClick={() => onChange(value)}>{value}</span>
                        </span>
                    );
                })}
            </span>
        </span>
    );
}
