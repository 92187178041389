import React, { useState, useEffect } from 'react';
import ImportExport from '../ImportExport';
import LabelMetadata from '../LabelMetadata';
import LabelSelect from '../LabelSelect';
import { IMarker, MAX_MARKER_SIZE, MIN_MARKER_SIZE } from '../Marker';
import CloudBucket from '../CloudBucket';
import CameraOptions from '../CameraOptions';
import type { TrackballControls } from '../CameraControls';
import InputDial from './InputDial';
import CrossSectionOptions from './CrossSectionOptions';
import type { ICrossSectionOptions } from './CrossSectionOptions';
import AppearanceOptions, { IAppearanceOptions } from '../AppearanceOptions';
import styles from './styles.module.css';
import { AnnotationData, Label, ReactSetter, UserInfo } from '../types';
import { VertexFaceIndex } from '../ImportExport/VertexFaceIndex';
import * as THREE from 'three';

interface ControlsProps {
    label: Label | null;
    setLabel: ReactSetter<Label | null>;
    setMarker: ReactSetter<IMarker | null>;
    visibleLabels: Label[];
    setVisibleLabels: ReactSetter<Label[]>;
    markerSize: number;
    setMarkerSize: ReactSetter<number>;
    isFilterFocused: boolean;
    setIsFilterFocused: ReactSetter<boolean>;
    annotationData: AnnotationData | undefined;
    setAnnotationData: ReactSetter<AnnotationData | undefined>;
    loadedAnnotationData: AnnotationData | undefined;
    setLoadedAnnotationData: ReactSetter<AnnotationData | undefined>;
    geometry: THREE.BufferGeometry | null;
    setGeometry: ReactSetter<THREE.BufferGeometry | null>;
    saveSnapshot: () => void;
    mesh: React.MutableRefObject<THREE.Mesh | null>;
    setFaceIndex: ReactSetter<VertexFaceIndex | null>;
    isDirty: boolean;
    setIsDirty: ReactSetter<boolean>;
    crossSectionOptions: ICrossSectionOptions;
    setCrossSectionOptions: ReactSetter<ICrossSectionOptions>;
    appearanceOptions: IAppearanceOptions;
    setAppearanceOptions: ReactSetter<IAppearanceOptions>;
    cameraControlsRef: React.MutableRefObject<TrackballControls | null>;
    baseColorAttr: React.MutableRefObject<THREE.BufferAttribute | undefined>;
    filterInputRef: React.MutableRefObject<HTMLInputElement | null>;
}

function Controls({
    label,
    setLabel,
    setMarker,
    visibleLabels,
    setVisibleLabels,
    markerSize,
    setMarkerSize,
    isFilterFocused,
    setIsFilterFocused,
    annotationData,
    setAnnotationData,
    loadedAnnotationData,
    setLoadedAnnotationData,
    geometry,
    setGeometry,
    saveSnapshot,
    mesh,
    setFaceIndex,
    isDirty,
    setIsDirty,
    crossSectionOptions,
    setCrossSectionOptions,
    appearanceOptions,
    setAppearanceOptions,
    cameraControlsRef,
    baseColorAttr,
    filterInputRef,
}: ControlsProps) {
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [bucket] = useState(new CloudBucket());

    useEffect(() => {
        if (!setMarker) return;
        setMarker(label ? { group: label.group, id: label.id, label: label.label, color: label.color } : null);
    }, [setMarker, label]);

    return (
        <div className={styles.container}>
            <ImportExport
                {...{
                    userInfo,
                    setUserInfo,
                    bucket,
                    annotationData,
                    setAnnotationData,
                    loadedAnnotationData,
                    setLoadedAnnotationData,
                    mesh,
                    geometry,
                    setGeometry,
                    setFaceIndex,
                    isDirty,
                    setIsDirty,
                }}
            />
            <LabelMetadata {...{ userInfo, annotationData, setAnnotationData, saveSnapshot }} />
            <div>Selected class: {`${label ? label.label : 'none'}`}</div>
            <LabelSelect
                onChange={l => {
                    setLabel(l);
                    if (l && label && l.group !== label.group) {
                        // adjust size only if we switch groups
                        setMarkerSize(l.markerSize);
                    }
                }}
                label={label}
                visibleLabels={visibleLabels}
                setVisibleLabels={setVisibleLabels}
                isFilterFocused={isFilterFocused}
                setIsFilterFocused={setIsFilterFocused}
                annotationData={annotationData}
                setAnnotationData={setAnnotationData}
                geometry={geometry}
                saveSnapshot={saveSnapshot}
                baseColorAttr={baseColorAttr}
                filterInputRef={filterInputRef}
            />
            <InputDial
                name={'Marker size'}
                value={markerSize}
                onChange={setMarkerSize}
                min={MIN_MARKER_SIZE}
                max={MAX_MARKER_SIZE}
            />
            <CameraOptions {...{ cameraControlsRef }} />
            <CrossSectionOptions {...{ crossSectionOptions, setCrossSectionOptions }} />
            <AppearanceOptions {...{ appearanceOptions, setAppearanceOptions }} />
        </div>
    );
}

export default Controls;
