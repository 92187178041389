import React, { useRef, useState, Suspense, useEffect, useMemo } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import * as THREE from 'three';

import Marker, { IMarker, MAX_MARKER_SIZE, MIN_MARKER_SIZE } from '../Marker';
import CameraControls, { TrackballControls } from '../CameraControls';
import Controls from '../Controls';
import DandyMesh from '../DandyMesh';
import useKeyboardShortcuts from '../useKeyboardShortcuts';
import useOperationStack from '../useOperationStack';
import { Loading } from './Loading';
import CameraRig from './CameraRig';
import HelpKey from '../HelpKey';

import options from '../config.json';
import { AnnotationData, Label } from '../types';
import { VertexFaceIndex } from '../ImportExport/VertexFaceIndex';
import { ICrossSectionOptions } from '../Controls/CrossSectionOptions';
import CameraTargetMarker from '../CameraControls/CameraTargetMarker';

function VertexPainter() {
    const initialCameraPosition: [number, number, number] = [0, 0, -50];

    const [loadedAnnotationData, setLoadedAnnotationData] = useState<AnnotationData>();
    const [annotationData, setAnnotationData] = useState<AnnotationData>();
    const [geometry, setGeometry] = useState<THREE.BufferGeometry | null>(null);
    const [faceIndex, setFaceIndex] = useState<VertexFaceIndex | null>(null);
    const [crossSectionOptions, setCrossSectionOptions] = useState<ICrossSectionOptions>({
        opacity: 10,
        distance: 0,
        isDrawn: true,
    });
    const [appearanceOptions, setAppearanceOptions] = useState({
        ambientLightIntensity: 1.5,
        saturation: 0,
        directionalLightIntensity: 2.5,
        doubleSided: true,
        syncedRotation: true,
    });

    const mesh = useRef<THREE.Mesh>(null);
    const camera = useRef<THREE.OrthographicCamera>(null);
    const cameraControls = useRef<TrackballControls>(null);
    const cameraTargetMarker = useRef<THREE.Mesh>(null);
    const cameraUpMarker = useRef<THREE.Mesh>(null);
    const light = useRef<THREE.DirectionalLight>(null);
    const filterInputRef = useRef<HTMLInputElement>(null);

    const [visibleLabels, setVisibleLabels] = useState(options.labels);
    const [label, setLabel] = useState<Label | null>(null);
    const [marker, setMarker] = useState<IMarker | null>(null);
    const [isSelecting, setIsSelecting] = useState(false); // whether we are selecting a label via ctrl-click (i.e. holding down control)
    const [isAltDown, setIsAltDown] = useState(false); // whether we are holding alt
    const [isControlDown, setIsControlDown] = useState(false); // whether we are holding control
    const [isShiftDown, setIsShiftDown] = useState(false); // whether we are holding shift
    const [hoverLabel, setHoverLabel] = useState<Label | null>(null);
    const [markerSize, setMarkerSize] = useState(20);

    const [mouseHover, setMouseHover] = useState(false);
    const meshPointerEvents = {
        onPointerOver: () => {
            setMouseHover(true);
        },
        onPointerOut: () => {
            setMouseHover(false);
        },
    };

    const deselectFilterInput = () => {
        filterInputRef.current && filterInputRef.current.blur();
    };

    const [isFilterFocused, setIsFilterFocused] = useState(false);
    const [hideAllLabels, setHideAllLabels] = useState(false);
    useKeyboardShortcuts(['Digit0'], () => {
        setHideAllLabels(!hideAllLabels);
    });

    useKeyboardShortcuts(['Equal', 'NumpadAdd'], () => {
        if (markerSize === MAX_MARKER_SIZE) return;
        setMarkerSize(markerSize + 1);
    });

    useKeyboardShortcuts(['Minus', 'NumpadSubtract'], () => {
        if (markerSize === MIN_MARKER_SIZE) return;
        setMarkerSize(markerSize - 1);
    });

    function handleCameraChange() {
        // set the light to be where the camera is
        if (camera.current && light.current) {
            const { x, y, z } = camera.current.position;
            light.current.position.set(x, y, z);
        }
        // make directional light point at where the camera is pointing at,
        // to avoid shadows
        if (cameraControls.current && light.current) {
            light.current.target.position.copy(cameraControls.current.target);
            light.current.target.updateMatrixWorld();
        }
        // update camera target marker position
        if (cameraControls.current && cameraTargetMarker.current && cameraUpMarker.current) {
            cameraTargetMarker.current.position.copy(cameraControls.current.target);
            cameraUpMarker.current.position.copy(cameraControls.current.target);
            const upDir = cameraControls.current.object.up.clone();
            cameraUpMarker.current.lookAt(upDir.add(cameraControls.current.target));
        }
    }

    useEffect(() => {
        handleCameraChange();
    });

    // allows us to adjust some global settings based on loaded mesh
    useEffect(() => {
        if (!geometry) {
            return;
        }
        if (!camera.current) {
            return;
        }
        // adjustments here will change the view when loaded, but will
        // not affect the default position for when we reset the camera

        // set camera to be looking at occlusal plane (roughly)
        const normals = geometry.getAttribute('normal');
        if (normals) {
            const v = new THREE.Vector3();
            for (let i = 0; i < normals.count; i++) {
                v.x += normals.getX(i);
                v.y += normals.getY(i);
                v.z += normals.getZ(i);
            }
            v.normalize().multiplyScalar(camera.current.position.length());
            // set the camera to be above the occlusal plane
            camera.current.position.set(v.x, v.y, v.z);
            // update the camera up direction as well
            const target = new THREE.Vector3(0, 0, 0);
            const lookDir = new THREE.Vector3().subVectors(target, camera.current.position).normalize();
            const globalUp = new THREE.Vector3(1, 2, 3); // choose a temp direction that is unlikely to be parallel to our look direction
            const right = new THREE.Vector3().crossVectors(lookDir, globalUp).normalize();
            const newUp = new THREE.Vector3().crossVectors(right, lookDir).normalize();
            camera.current.up.copy(newUp);
            // finally set it to look at target (this uses the up direction so do it last)
            camera.current.lookAt(target);
        }
        // increase zoom
        camera.current.zoom = 10;
        camera.current.updateProjectionMatrix();
    }, [geometry, camera]);

    const activeMarkerHover = mouseHover && (marker != null || isSelecting);
    const markerGroup = marker ? marker.group : null;
    const markerId = marker ? marker.id : null;

    const orderedVisibleLabels = useMemo(() => {
        // Move active label to the end of the labels list,
        // this way restoreColorByMasks will render active
        // label on top
        const orderedVisibleLabels = visibleLabels.filter(l => l.group !== markerGroup || l.id !== markerId);
        const visibleActiveLabel = visibleLabels.find(l => l.group === markerGroup && l.id === markerId);
        visibleActiveLabel && orderedVisibleLabels.push(visibleActiveLabel);
        return orderedVisibleLabels;
    }, [visibleLabels, markerGroup, markerId]);

    const { saveSnapshot, isDirty, setIsDirty, baseColorAttr } = useOperationStack({
        geometry,
        orderedVisibleLabels,
        annotationData,
        setAnnotationData,
    });

    // don't allow rotation if:
    // - we have a selected painting label, or
    // - we are holding ctrl to select a label on the mesh
    // and we are not holding shift (which bypasses the above)
    // or we are doing a flood fill
    // unless we have hidden all labels (in which case it's ok to rotate)
    const isFloodFill = isShiftDown && isAltDown && isControlDown;
    const noRotate = (((label != null || isSelecting) && !isShiftDown) || isFloodFill) && !hideAllLabels;

    return (
        <>
            <Controls
                mesh={mesh}
                geometry={geometry}
                setGeometry={setGeometry}
                setFaceIndex={setFaceIndex}
                setMarker={setMarker}
                {...{
                    label,
                    setLabel,
                    visibleLabels,
                    setVisibleLabels,
                    hideAllLabels,
                    setHideAllLabels,
                    markerSize,
                    setMarkerSize,
                    crossSectionOptions,
                    setCrossSectionOptions,
                    appearanceOptions,
                    setAppearanceOptions,
                    isFilterFocused,
                    setIsFilterFocused,
                    loadedAnnotationData,
                    setLoadedAnnotationData,
                    annotationData,
                    setAnnotationData,
                    saveSnapshot,
                    isDirty,
                    setIsDirty,
                    cameraControlsRef: cameraControls,
                    baseColorAttr,
                    filterInputRef,
                }}
            />
            <HelpKey showModifier={isFilterFocused} />

            <Canvas linear legacy flat onClick={() => deselectFilterInput()}>
                <CameraControls
                    cameraControlsRef={cameraControls}
                    onChange={handleCameraChange}
                    noRotate={noRotate}
                    rotateSpeed={5}
                    syncedRotation={appearanceOptions.syncedRotation}
                />
                <CameraRig cameraRef={camera} fov={75} position={initialCameraPosition} />
                <ambientLight intensity={appearanceOptions.ambientLightIntensity} />
                <directionalLight ref={light} intensity={appearanceOptions.directionalLightIntensity} />
                <Suspense fallback={<Loading />}>
                    <DandyMesh
                        geometry={geometry}
                        meshRef={mesh}
                        {...meshPointerEvents}
                        crossSectionOptions={crossSectionOptions}
                        cameraRef={camera}
                        faceIndex={faceIndex}
                        setFaceIndex={setFaceIndex}
                        doubleSided={appearanceOptions.doubleSided}
                    >
                        <Marker
                            marker={marker}
                            visibleLabels={visibleLabels}
                            orderedVisibleLabels={orderedVisibleLabels}
                            visible={activeMarkerHover}
                            saturation={appearanceOptions.saturation}
                            hideAllLabels={hideAllLabels}
                            setLabel={setLabel}
                            erase={false}
                            faceIndex={faceIndex}
                            size={markerSize}
                            setMarkerSize={setMarkerSize}
                            markerUniform={true}
                            alpha={0}
                            isSelecting={isSelecting}
                            setIsSelecting={setIsSelecting}
                            setIsAltDown={setIsAltDown}
                            setIsControlDown={setIsControlDown}
                            setIsShiftDown={setIsShiftDown}
                            hoverLabel={hoverLabel}
                            setHoverLabel={setHoverLabel}
                            saveSnapshot={saveSnapshot}
                            baseColorAttr={baseColorAttr}
                            crossSectionOptions={crossSectionOptions}
                        ></Marker>
                        <CameraTargetMarker
                            cameraTargetMarkerRef={cameraTargetMarker}
                            cameraUpMarkerRef={cameraUpMarker}
                        />
                    </DandyMesh>
                </Suspense>
            </Canvas>
        </>
    );
}

export default VertexPainter;
