import { ReactSetter } from '../types';
import React from 'react';

export interface IAppearanceOptions {
    saturation: number;
    directionalLightIntensity: number;
    ambientLightIntensity: number;
    doubleSided: boolean;
    syncedRotation: boolean;
}

interface AppearanceOptionsProps {
    appearanceOptions: IAppearanceOptions;
    setAppearanceOptions: ReactSetter<IAppearanceOptions>;
}

export default function AppearanceOptions({ appearanceOptions, setAppearanceOptions }: AppearanceOptionsProps) {
    const { saturation, ambientLightIntensity, directionalLightIntensity, doubleSided, syncedRotation } =
        appearanceOptions;

    function apply(changes: Partial<IAppearanceOptions>) {
        setAppearanceOptions({
            ...appearanceOptions,
            ...changes,
        });
    }

    return (
        <>
            <label>Mesh colors saturation</label>
            <input
                type={'range'}
                min={-1.0}
                max={1.0}
                step={0.01}
                value={saturation}
                onChange={e => apply({ saturation: parseFloat(e.target.value) })}
            />
            <label>Directional light intensity</label>
            <input
                type={'range'}
                min={0.0}
                max={5.0}
                step={0.0125}
                value={directionalLightIntensity}
                onChange={e => apply({ directionalLightIntensity: parseFloat(e.target.value) })}
            />
            <label>Ambient light intensity</label>
            <input
                type={'range'}
                min={0.0}
                max={3.0}
                step={0.0125}
                value={ambientLightIntensity}
                onChange={e => apply({ ambientLightIntensity: parseFloat(e.target.value) })}
            />

            <label>Mesh surface</label>
            <button onClick={e => apply({ doubleSided: !doubleSided })}>
                {doubleSided ? 'Make Single-Sided' : 'Make Double-Sided'}
            </button>

            <label>Rotation mouse sync</label>
            <button onClick={e => apply({ syncedRotation: !syncedRotation })}>
                {syncedRotation ? 'Unsync mouse and rotation' : 'Sync mouse and rotation'}
            </button>
        </>
    );
}
