import * as THREE from 'three';

interface CameraTargetMarkerProps {
    cameraTargetMarkerRef: React.MutableRefObject<THREE.Mesh | null>;
    cameraUpMarkerRef: React.MutableRefObject<THREE.Mesh | null>;
}

export default function CameraTargetMarker({ cameraTargetMarkerRef, cameraUpMarkerRef }: CameraTargetMarkerProps) {
    return (
        <>
            <mesh ref={cameraTargetMarkerRef}>
                <boxGeometry attach="geometry" args={[0.5, 0.5, 0.5]} />
                <meshStandardMaterial attach="material" color={new THREE.Color(0x888888)} />
            </mesh>
            <mesh ref={cameraUpMarkerRef}>
                <boxGeometry attach="geometry" args={[0.1, 0.1, 2.0]} />
                <meshStandardMaterial attach="material" color={new THREE.Color(0x444444)} />
            </mesh>
        </>
    );
}
